import * as React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { Container } from "react-bootstrap"

import Header from "../components/header"
import Footer from "../components/footer"

const Container404 = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  text-align: center;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5vh;
  color: #84b250;
  font-weight: bold;
`
const MainContainer = styled(Container)`
  height: 100vh;
  padding: 0 !important;
`

const NotFoundPage = () => {
  return (
    <MainContainer fluid>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trash Encyclopedia</title>
      </Helmet>
      <Header state="mainpage" />
      <Container404>Error 404</Container404>
      <div className="section fp-auto-height">
        <Footer state="mainpagefooter" />
      </div>
    </MainContainer>
  )
}

export default NotFoundPage
